import logo from './logo.svg';
import './App.css';
import MenuRenderer from "./MenuRenderer";
import {useEffect, useState} from "react";
import {getAllCategories, getAllItems} from "./api/wordpress";

function App() {

    const [menuData, setMenuData] = useState([]);

    useEffect(() => {

        // Fetch categories and menu items
        const fetchData = async () => {
            try {
                // Combine the data
                const categories = await getAllCategories();
                const items = await getAllItems();
                // Map items to their categories
                const data = categories.map(category => ({
                    ...category,
                    items: items.filter(item => item.acf.category === category.id),
                }));

                setMenuData(data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

  return (
    <div className="App">
      <MenuRenderer menuData={menuData}/>
    </div>
  );
}

export default App;
