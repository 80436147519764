// src/MenuRenderer.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFDocument from "./PDFDocument";
import {getAllCategories, getAllItems} from "./api/wordpress";

const MenuRenderer = ({menuData}) => {
  
  const [displayPrices, setDisplayPrices] = useState(false)
  // Helper function to generate the filename
  const generateFileName = () => {
    const date = new Date();
    const formattedDate = date.toISOString().replace(/[:T]/g, '-').slice(0, 19);
    return `menu_${formattedDate}.pdf`;
  };


  return (
    <div className="la-farm">
      
      
      <PDFDownloadLink className="menu__download" document={<PDFDocument data={menuData} showPrices={displayPrices}/>}
                       fileName={generateFileName()}>
        {({blob, url, loading, error}) => {
          return loading ? 'Loading document...' : 'Download now!'
        }
        }
      </PDFDownloadLink>
      
      <div className="price-select">
        <input className="price-select__checkbox" type="checkbox" checked={displayPrices}
               onClick={() => setDisplayPrices(!displayPrices)} id="display_prices"/>
        <label className="price-select__label" htmlFor="display_prices">Display prices</label>
      </div>
      
      <div className="menu__display">
        <ul id="lafarm-menu">
          {menuData.map(category => (
            <li className="lafarm-menu__section" key={category.id}>
              <h3 className="lafarm-menu__section-title">{category.name}</h3>
              <ul className="lafarm-menu__items">
                {category.items.map(item => (
                  <li className="lafarm-menu__item" key={item.id}>
                    <h4 className="lafarm-menu__item-title">{item.title.rendered}{item.acf.zero &&
                      <span className="lafarm-menu__item-zero"> 0KM</span>}</h4>
                    <p className="lafarm-menu__item-info">{item.acf.description}{item.acf.price && displayPrices &&
                      <span className="lafarm-menu__item-price"> | {item.acf.price}</span>}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MenuRenderer;
