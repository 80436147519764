import axios from 'axios';

const apiBaseUrl = 'https://la-farm.com/wp-json/wp/v2';

// Function to fetch all data from a specific endpoint
async function fetchAllFromEndpoint(endpoint) {
    let results = [];
    let page = 1;
    let total = null;

    try {
        // Loop until all pages are fetched
        do {
            const response = await axios.get(`${apiBaseUrl}/${endpoint}`, {
                params: {
                    orderby: endpoint === 'menu_category' ? 'term_order' : 'menu_order',
                    order: 'asc',
                    per_page: 100,
                    page,
                }
            });
            results = results.concat(response.data);
            total = parseInt(response.headers['x-wp-total']);
            page++;
        } while (results.length < total);
    } catch (error) {
        console.error(`Error fetching data from ${endpoint}:`, error);
    }

    return results;
}

// Exported function to get all categories
export async function getAllCategories() {
    return fetchAllFromEndpoint('menu_category');
}

// Exported function to get all items
export async function getAllItems() {
    return fetchAllFromEndpoint('menu_item');
}
